const Experience = ({ title, description = "", imageSrc, ...props }) => {

    return (
        <div className="card flex justify-items-center">
            <div className="card-header flex item-center py-5">
                <img className="icon-md mr-5 md:max-w-40 md:max-h-24 min-[360px]:w-32" src={imageSrc} />
                <div className="flex-1">
                    <h3 className="font-semibold">{title}</h3>
                    <h4>{description}</h4>
                </div>
            </div>
            <ul>
                <li></li>
            </ul>
        </div>
    )
}

export default Experience