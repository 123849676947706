const Skill = ({title, imageSrc,...props}) => {
    return <div className="card flex justify-items-center">
                <div className="card-header pb-0">
                    <div className="logo mr-2">
                        <img className="icon" src={imageSrc} />
                    </div>
                    <label className="sub-title">
                        {title}
                    </label>
                </div>
            </div>
}

export default Skill