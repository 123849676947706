import { useEffect, useState } from "react";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Skill from "./components/Skill";
import { useRef } from "react";
const App = () => {

    const [navbarOpen, setNavbarOpen] = useState("")
    const navRef = useRef(null)
    const handleClickMenu = (selector) => {
        document.querySelector(selector).scrollIntoView({behavior: 'smooth'});
        setNavbarOpen("")
    }
    const onClickNav = () => {
        if(navbarOpen == "show") {
            setNavbarOpen("")
        }else{
            setNavbarOpen("show")
        }
    }
    useEffect(() => {
        window.addEventListener("resize", (e) => {
            setNavbarOpen("")
        })
    }, [])
    return (<div className="text-slate-700">
        <div className="w-full fixed bg-white  border-slate-200 shadow-slate-50">
            <div className="lg:container lg:mx-auto flex justify-between md:m-0 min-[320px]:m-3 min-[320px]:mx-6">
                <div className="self-center">
                    <div className="text-xl"><span className="font-bold text-violet-700">m</span>yunus</div>
                </div>
                <button onClick={onClickNav} className="md:hidden text-xl">
                <i className="fa-solid fa-bars"></i>
                </button>
                <div className={`navbar ${navbarOpen}`} ref={navRef}>
                    <ul className="flex">
                        <li className="px-6 md:py-6 min-[320px]:py-2 hover:text-violet-700"><a className="cursor-pointer" onClick={() => handleClickMenu("#about")}>About Me</a></li>
                        <li className="px-6 md:py-6 min-[320px]:py-2 hover:text-violet-700"><a className="cursor-pointer" onClick={() => handleClickMenu("#specialization")}>Specialization</a></li>
                        <li className="px-6 md:py-6 min-[320px]:py-2 hover:text-violet-700"><a className="cursor-pointer" onClick={() => handleClickMenu("#experience")}>Work Experience</a></li>
                        <li className="px-6 md:py-6 min-[320px]:py-2 hover:text-violet-700"><a className="cursor-pointer" onClick={() => handleClickMenu("#portfolio")}>Portfolio</a></li>
                        <li className="px-6 md:py-6 min-[320px]:py-2 hover:text-violet-700"><a className="cursor-pointer" onClick={() => handleClickMenu("#skills")}>Skills</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="w-full header-background" id="about">
            <div className="lg:container mx-auto flex header min-[320px]:flex-col md:flex-row md:mt-0 min-[320px]:pt-10" >
                <div className="flex-1 flex justify-center">
                    <div className="image-header md:h-96 md:w-96 min-[320px]:h-60 min-[320px]:w-60 min-[320px]:mb-10 min-[320px]:mt-10 self-center border-violet-500 bg-slate-100">
                        <img src="./images/pass-photo-no-bg.png" />
                    </div>
                </div>
                <div className="header-description min-[320px]:pb-10">
                    <h1 className="text-4xl mb-2 font-bold text-violet-700">Software Engineer</h1>
                    <h2 className="text-lg font-semibold">Hello, I'm Yunus. 31 years old</h2>
                    <p className="text-lg">
                        Software engineer with 7 years experience in building and development systems
                    </p>
                    <div className="mt-2 flex gap-2">
                        <a href="mailto:mkhyunus@gmail.com" target="__self" className="px-5 py-3 bg-violet-500 text-white rounded-full shadow-md cursor-pointer">Contact Me</a>
                        <a href="./cv/mukhammad_yunus_cv.pdf" className="px-5 py-3 text-violet-500 border border-violet-500 rounded-full shadow-md cursor-pointer">Donwload Resume</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-slate-50 pt-10" id="specialization">
            <div className="lg:container mx-auto mt-5">
                <h2 className="text-2xl text-center mx-auto mb-10">Specialization</h2>
                <div className="grid md:grid-cols-4 md:gap-4 min-[360px]:gap-2 min-[360px]:px-2 text-lg mt-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="logo mr-2 min-[320px]:w-10 min-[320px]:max-w-10">
                                <img className="icon min-[320px]:w-8 min-[320px]:max-w-8" src="./images/api-logo.png" />
                            </div>
                            <label className="flex-auto sub-title">
                                Backend Development
                            </label>
                        </div>
                        <div className="card-body">
                            <p className="text-sm">Developing backend applications like api services with native langgue or framework</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className="logo mr-2 min-[320px]:w-10 min-[320px]:max-w-10">
                                <img className="icon min-[320px]:w-8 min-[320px]:max-w-8" src="./images/logo512.png" />
                            </div>
                            <label className="flex-auto sub-title">
                                Frontend Development
                            </label>
                        </div>
                        <div className="card-body">
                            <p className="text-sm">Developing front-end applications with HTML, CSS, javascript, ReactJS and others framework</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className="logo mr-2 min-[320px]:w-10 min-[320px]:max-w-10">
                                <img className="icon min-[320px]:w-8 min-[320px]:max-w-8" src="./images/redhat.png" />
                            </div>
                            <label className="flex-auto sub-title">
                                Linux Server
                            </label>
                        </div>
                        <div className="card-body">
                            <p className="text-sm">Setup and configure the server for applications</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className="logo mr-2 min-[320px]:w-10 min-[320px]:max-w-10">
                                <img className="icon min-[320px]:w-8 min-[320px]:max-w-8" src="./images/cloud-server.png" />
                            </div>
                            <label className="flex-auto sub-title">
                                Web Server
                            </label>
                        </div>
                        <div className="card-body">
                            <p className="text-sm">Setup and configure the web server like Nginx for applications</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-slate-50 pt-10" id="experience">
            <div className="lg:container mx-auto mt-10">
                <h2 className="text-2xl text-center mx-auto mb-10 ">Work Experience</h2>
                <div className="grid md:grid-cols-2 md:gap-4 min-[360px]:gap-2 min-[360px]:px-2 mt-3">
                    
                    <Experience title="Software Engineer" description="PT Aplikasi PaidbaQ Indonesia" imageSrc="./images/logo-paidbaq.png" />
                    <Experience title="IT Programmer" description="Dinas Komunikasi, Informatika, dan Statistik DKI Jakarta" imageSrc="./images/logodki.png" />
                    <Experience title="IT Application Solution Specialist" description="PT Vads Indonesia" imageSrc="./images/vads-indonesia.png" />
                    <Experience title="IT Programmer" description="PT Aplikasi PaidbaQ Indonesia" imageSrc="./images/logo-paidbaq.png" />
                    <Experience title="Application Development Staff" description="PT Dua Kelinci" imageSrc="./images/logo-dk.png" />
                    
                </div>
            </div>
        </div>

        <div className="bg-slate-50 pt-10" id="portfolio">
            <div className="lg:container mx-auto mt-10">
                <h2 className="text-2xl text-center mx-auto mb-10 ">Portfolio</h2>
                <div className="grid md:grid-cols-3 md:gap-6 min-[360px]:gap-2 min-[360px]:px-2 mt-3">
                    <Portfolio 
                        url="https://paidbaq.yunus.cloud/backos/login"
                        imageSrc="./images/portfolio/backos2.png" 
                        title="Internal Application PaidbaQ" 
                        description="The internal application for manage system like setting admin fee, clients, cycle, invoice, disbursement and others" />

                    <Portfolio 
                        url="https://paidbaq.yunus.cloud/clientarea/login"
                        imageSrc="./images/portfolio/clientarea.png" 
                        title="Client Area PaidbaQ" 
                        description="The application which provides PaidbaQ clients for managing their employees, credit limits and others" />
                    
                    <Portfolio 
                        url="https://paidbaq.yunus.cloud"
                        imageSrc="./images/portfolio/web-app.png" 
                        title="PaidbaQ User" 
                        description="The application which provides PaidbaQ users for earned wage access quickly, absence, shopping" />
                    
                    <Portfolio 
                        url="https://jalahoaks.jakarta.go.id"
                        imageSrc="./images/portfolio/jalahoaks.png" 
                        title="Jalahoaks" 
                        description="The application provided PaidbaQ users for earned wage access quickly, absence, shopping" />
                    
                    <Portfolio 
                        url="https://jakarta.go.id"
                        imageSrc="./images/portfolio/jakgo.png" 
                        title="Jakgo" 
                        description="The web portal application which provides information about Jakarta" />

                </div>
            </div>
        </div>

        <div className="bg-slate-50 pt-10 pb-10" id="skills">
            <div className="lg:container mx-auto mt-10">
                <h2 className="text-2xl text-center mx-auto mb-10 ">Skills</h2>
                <div className="grid md:grid-cols-5 md:gap-4 min-[360px]:grid-cols-2 min-[360px]:gap-2 min-[360px]:px-2 mt-3">
                    <Skill title="HTML5" imageSrc="./images/skills/html-icon.png" />
                    <Skill title="CSS3" imageSrc="./images/skills/css3-icon.png" />
                    <Skill title="Javascript" imageSrc="./images/skills/js-icon.png" />
                    <Skill title="Jquery" imageSrc="./images/skills/jquery-icon.png" />
                    <Skill title="PHP" imageSrc="./images/skills/php-icon.png" />
                    <Skill title="Nodejs" imageSrc="./images/skills/nodejs-icon.png" />
                    <Skill title="Java" imageSrc="./images/skills/java-icon.png" />
                    <Skill title="Laravel" imageSrc="./images/skills/laravel-icon.png" />
                    <Skill title="MySql" imageSrc="./images/skills/mysql-icon.png" />
                    <Skill title="Nginx" imageSrc="./images/skills/nginx-icon.png" />
                    <Skill title="Linux" imageSrc="./images/skills/linux-icon.png" />
                    <Skill title="AWS EC2" imageSrc="./images/skills/aws-icon.png" />
                    <Skill title="Docker" imageSrc="./images/skills/docker-icon.png" />
                    <Skill title="Jenkins" imageSrc="./images/skills/jenkins-icon.png" />
                    <Skill title="Git" imageSrc="./images/skills/git-icon.png" />
                </div>
            </div>
        </div>
        <div className="mb-10 pt-10 text-center">
            <button onClick={() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                })
            }}>Back To Top</button>
        </div>
    </div>
    )
}

export default App;