const Portfolio = ({ title, description = "", imageSrc, url="", ...props }) => {

    return (
        <a href={url} target="__self" className="card card-portfolio">
            <div className="image-container">
                <img src={imageSrc} />
            </div>
            <div className="card-body">
                <h3 className="sub-title pt-2">{title}</h3>
                <p>{description}</p>
            </div>
        </a> 
    )
}

export default Portfolio